import { Suspense, lazy } from "react";
import Loader from "../custom/loader/main";

const Main = lazy(() => import("../components/costOfCare"));

const CostOfCare = () => {
  return (
    <Suspense fallback={<Loader />}>
        <Main />
      </Suspense>
  )
}

export default CostOfCare

