import React from 'react'
import styles from "./main.module.css"
const Loader = () => {
  return (
    <div className={styles.div}>
            <div className={styles.loader}></div>
    </div>

  )
}

export default Loader