import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, About, OurServices, Career, ContactUs, DomiciliaryCare, LiveInCare, SupportedLiving, ConditionsWeSupport, CostOfCare } from "./screens";
import Application from "./screens/application";
import { Cloudinary } from "@cloudinary/url-gen";
// import About from "./components/about";
// import OurServices from "./components/ourServices";
// import DomiciliaryCare from "./components/domiciliaryCare";
// import LiveInCare from "./components/liveInCare";
// import SupportedLiving from "./components/supportedLiving";
// import Career from "./components/career";
// import ContactUs from "./components/contactUs";
// import ConditionsWeSupport from "./components/conditionsWeSupport";
// import CostOfCare from "./components/costOfCare";
function App() {
  const cld = new Cloudinary({ cloud: { cloudName: "di3exjwr9" } });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/our-services/domiciliary-care" element={<DomiciliaryCare />} />
        <Route path="/our-services/live-in-care" element={<LiveInCare />} />
        <Route path="/our-services/supported-living" element={<SupportedLiving />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/conditions-we-support" element={<ConditionsWeSupport />} />
        <Route path="/cost-of-care" element={<CostOfCare />} />
        <Route path="/career/apply" element={<Application />} />
      </Routes>
    </Router>
  );
}

export default App;
